body {
  margin: 0;
  padding: 0;
  font-family: 'Courier New,Courier', 'Lucida Sans Typewriter', 'Lucida Typewriter', monospace!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Courier New,Courier', 'Lucida Sans Typewriter', 'Lucida Typewriter', monospace!important;
}
